/****footer****/
.footer {
    background-color: var(--color5);
    color: var(--color4);
    border-radius: 30px 30px 0 0;
    width: 100vw;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-block: 5vmin;
    padding-inline: 5vw;
    gap: 3vh;

    .logo-footer {
        width: 100%;
        max-width: 100px;
    }

    .social_column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1em;

        .social__logo {
            display: flex;
            flex-direction: row;
            gap: 1rem;

            .footer__social {
              
                color: var(--color1);
                border-radius: 100%;
                padding: 3px;
                font-size: clamp(.5rem, 10vw, 1.5rem);
                text-align: center;
                transition: all 0.75s;
                width: 2rem;
                height: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;

                &:hover {
                    color: var(--color2);
                    background-color: var(--color1);
                    cursor: pointer;
                }
            }
        }

        button {
            margin-top: 1em;
            background: var(--color2);
            color: var(--color1);
            padding-inline: 3rem;
            padding-block: .75rem;
            border-radius: 20px;
            outline: none;
            border: none;
            // color: var(--color1);
            // background: var(--color2);
            color: var(--color2);
            background: var(--color1);
            font-size: 1em;
            font-weight: bold;
            cursor: pointer;
            transition: .3s all ease-in-out;

            &:hover {
                color: var(--color1);
                background: var(--color2);
            }
        }
    }

    .map {
        // width: inherit;
        // max-height: calc(100vh - var(--nav-height));
        height: 30vh;
        border-radius: 30px;
    }



    .footer-bottom {
        // display: flex;
        // flex-wrap: wrap;
        // flex-direction: row;
        justify-content: space-between;

        // justify-content: center;
        // padding-inline: 20vmin;
        padding-block-start: 4.5vmin;
        padding-block-end: 10vmin;
        gap: 1rem;
        width: inherit;
        display: grid;


        .social_column,
        .logo_column {
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: center;
        }

        .social_column {
            gap: 1em
        }

        .logo_column {
            gap: 1em
        }
    }
}


// /* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px) {
//     .footer-bottom {
//         grid-template-columns: repeat(1, 1fr);
//     }
// }

// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {
//     .footer-bottom {
//         grid-template-columns: repeat(1, 1fr);
//     }
// }

// /* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {
//     .footer-bottom {
//         grid-template-columns: repeat(2, 1fr);
//     }
// }

// /* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {
//     .footer-bottom {
//         grid-template-columns: repeat(2, 1fr);
//     }
// }

// /* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {
//     .footer-bottom {
//         grid-template-columns: repeat(2, 1fr);
//     }
// }

// .footer {
//     button {
//         background: var(--color2);
//         color: var(--color1);
//         padding-inline: 3rem;
//         padding-block: .75rem;
//         border-radius: 20px;
//         outline: none;
//         border: none;
//         // color: var(--color1);
//         // background: var(--color2);
//         color: var(--color2);
//         background: var(--color1);
//         font-size: 1em;
//         font-weight: bold;
//         cursor: pointer;
//         transition: .3s all ease-in-out;

//         &:hover {
//             color: var(--color1);
//             background: var(--color2);
//         }
//     }
// }

// .footer_top {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     gap: 1rem;
// }

// .logo-footer {
//     width: 100%;
//     max-width: 100px;
// }


// .social__logo {
//     display: flex;
//     flex-direction: row;
//     gap: 1rem;
// }

// .footer__social {
//     color: inherit;
//     border-radius: 100%;
//     padding: 8px;
//     font-size: clamp(.5rem, 10vw, 1.5rem);
//     text-align: center;
//     transition: all 0.75s;
//     width: 2rem;
//     height: 2rem;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     align-content: center;
// }

// .footer__social:hover {
//     color: var(--color2);
//     background-color: var(--color1);
// }