// $itemImageWidth: 18rem;
.search-box {
    padding-inline: 1em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    // height: 35px;
    gap: 0.5rem;
    color: var(--color1);
    margin-bottom: 3vh;

    .search-box_icon {
        width: 1rem;
        height: 1rem;
    }

    input {
        height: 35px;
        min-width: 250px;
        padding: 0 .5em;
        outline: none;
        border: none;
        border-bottom: 1px solid var(--color1);
        background-color: transparent;
    }
}

.categoriesDivTitle {
    padding-inline: 1em;
    color: var(--color1) !important;
    margin-block: 1vh;
}

.previous-searches.sticky {
    padding-inline: 1em;
    position: sticky;
    top: var(--nav-height);
    z-index: 2;
}

.previous-searches {

    background: var(--color2);
    flex-shrink: 3;
    padding-block: 1rem;

    .previous-searches-container {
        overflow: hidden;
        position: relative;
    }


    .previous-searches-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        overflow-x: auto;
        margin-bottom: 0rem;
        gap: .5vw;

        .search-item {
            display: inline-block;
            margin: 0 1em 1em 0;
            color: var(--color5) !important;
            cursor: pointer;
        }
    }




    .icon {
        width: 2.5rem;
        height: 2.5rem;
        padding: .5rem;
        color: var(--color2);
        background-color: var(--color1);
        // font-size: 1.5rem;
        overflow: hidden;
        border-radius: 100%;
        cursor: pointer;

    }

    // .trendingContainer {
    //     padding-block-start: 4rem;
    //     font-size: 2rem;
    //     font-weight: bold;
    //     display: flex;
    //     flex-direction: column;
    //     gap: 2rem;

    //     .swiper-slide {
    //         font-size: 25% !important;
    //         transition: .5s;
    //     }
    // }
}

.swiper-slide .food-name,
.swiper-slide-sticky {
    font-size: 1em !important;
    transition: .5s;
}

.swiper-slide-sticky {
    border: 2px solid var(--color1);
    color: var(--color1);
    margin-inline: .5rem;
    padding-inline: .5em;
    padding-block: .2em;
    text-align: center;
    border-radius: .5em;
    cursor: pointer;
    white-space: nowrap;
}

.swiper-slide-sticky-active,
.swiper-slide-sticky:hover {
    border: 2px solid var(--color1);
    color: var(--color2);
    background: var(--color1);
}



.swiper-slide.sticky {
    border: 2px solid var(--color1);
    color: var(--color1);
    margin-inline: .25em !important;
    padding-inline: .5em !important;
    padding-block: .2em !important;
    text-align: center;
    border-radius: .5em;
    cursor: pointer;
    white-space: nowrap;

    font-size: 1em !important;
    transition: .5s;

    height: fit-content !important;
    width: fit-content !important;
}

.swiper-slide.active.sticky {
    border: 2px solid var(--color1);
    color: var(--color2);
    background: var(--color1);
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

    // .previous-searches {
    .swiper-slide:not(.sticky) {
        width: 25vw !important;
        height: 20vw !important;

        .food-name {
            max-width: 20vw !important;
        }
    }

    // }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    // .previous-searches {
    .swiper-slide:not(.sticky) {
        width: 25vw !important;
        height: 20vw !important;

        .food-name {
            max-width: 20vw !important;
        }
    }

    // }
}



/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    // .previous-searches {
    .swiper-slide:not(.sticky) {
        width: calc(18rem /1.5) !important;
        height: calc(18rem/1.5) !important;

        .food-name {
            max-width: calc(18rem /1.5) !important;
        }
    }

    // }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    // .previous-searches {
    .swiper-slide:not(.sticky) {
        width: calc(18rem/1.5) !important;
        height: calc(18rem/1.5) !important;

        .food-name {
            max-width: calc(18rem /1.5) !important;
        }
    }

    // }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    // .previous-searches {
    .swiper-slide:not(.sticky) {
        width: calc(18rem/1.5) !important;
        height: calc(18rem/1.5) !important;

        .food-name {
            max-width: calc(18rem /1.5) !important;
        }
    }

    // }
}