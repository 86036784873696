.navbar {
    height: var(--nav-height);
    box-shadow: 0 3px 6px var(--shadow-color);
    background-color: var(--color5);
    padding-inline: 4vw;
    color: var(--text-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: calc(var( --dialog-zIndex) + 1);
    width: 100%;
    left: 0;

    .icon{
        color: var(--color1);
        width: 2rem;
        height: 2rem;
        cursor: pointer;
    }
    
    .logo {
        font-size: 1.5em;
        font-weight: bolder;
        color: var(--primary-color);
        text-shadow: 0 1px 3px var(--shadow-color);
        height: inherit;
        width: max-content;
        object-fit: contain;
        max-width: 40vw;
        padding: 15px;
        cursor: pointer;
        span {
            color: var(--text-color);
        }
    }

    .nav-links {
        a {
            margin-left: 1em;
            color: var(--text-light);
            letter-spacing: 2px;
            text-transform: uppercase;
            position: relative;
            font-size: .9em;

            &::before {
                content: '';
                display: inline-block;
                height: 3px;
                width: 100%;
                background-color: var(--primary-color);
                position: absolute;
                bottom: -5px;
                left: 0;
                transform: scaleX(0);
                transition: getAnimationDuration(.2s) ease-in-out;
            }

            &:hover {
                &::before {
                    transform: scaleX(1);
                }
            }

            &.active {
                color: var(--primary-color);
                font-weight: bold;
            }
        }
    }

    .sidebar-btn {
        height: 1.5rem;
        width: 1.5rem;
        position: relative;
        display: block;
        cursor: pointer;

        .bar {
            border-radius: 5px;
            height: .3rem;
            width: 100%;
            background-color: var(--color3);
            position: absolute;
            transition: transform getAnimationDuration(.5s);

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2) {
                top: 50%;
                transform-origin: right;
                transform: scaleX(.8) translateY(-50%);

            }

            &:nth-child(3) {
                top: 100%;
                transform-origin: right;
                transform: scaleX(1.1) translateY(-100%);
            }
        }

        &.active {
            .bar {
                transform-origin: center;
                top: 50%;

                &:nth-child(1) {
                    transform: translate(0, -50%) scale(1) rotate(45deg);
                }

                &:nth-child(2) {
                    transform: scaleX(0) translateY(-50%);
                }

                &:nth-child(3) {
                    transform: translate(0, -50%) scale(1) rotate(-45deg);
                }
            }
        }
    }
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 260px;
    z-index: 999;
    background-color: var(--background-color);
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.2),
        3px 0 5px var(--shadow-color);
    animation: getAnimationDuration(.3s) slideInLeft;

    .sidebar-link {
        display: block;
        padding: .5em 1em;
        border-left: .4em solid transparent;
        margin-bottom: 1em;
        color: var(--text-color);
        font-size: 1.1em;
        transform: translateX(-100%);
        animation: getAnimationDuration(.3s) slideInLeft forwards;

        @for $i from 1 through 3 {
            &:nth-child(#{$i}) {
                animation-delay: #{$i * .1}s;
            }
        }

        &.active {
            border-left-color: var(--primary-color);
        }

        &:hover {
            border-left-color: var(--primary-color);
            background-color: rgba(0, 0, 0, 0.05);
        }

        svg {
            width: 1.2em;
            margin-right: .5em;
        }
    }
}