.recipes-container {
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-template-columns: repeat(5, minmax(150px, 1fr));

    grid-gap: 1.5em;
    margin-inline: 1em;
}

.recipe-card {

    .react-loading-skeleton-container {
        position: static;
    }

    isolation: isolate;
    overflow: hidden;
    box-shadow: 0 5px 15px var(--shadow-color);
    background-color: var(--color1);
    color: var(--color2);
    border-radius: 25px;

    .recipe-card-info {
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .custom-image:hover {
        // transition-duration: .7s;
        -webkit-transition: all .7s;
        -moz-transition: all .7s;
        -o-transition: all .7s;
        transition: all .7s;
        transform: scale(1.15);
        cursor: pointer;
    }

    .auther-img {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        border: 3px solid var(--color2);
        box-shadow: 0 1px 3px var(--shadow-color);
        background-color: var(--color1);
        margin-top: -50px;
        z-index: 2;
        object-fit: cover;
    }

    .recipe-title {
        font-size: 1.25em;
        font-weight: bold;
        margin-bottom: .5em;
        margin-top: .25em;
        position: static;
    }

    .recipe-desc {
        color: var(--text-light);
        font-size: 0.9em;
        letter-spacing: 1px;
        max-height: 2rem;
        line-height: 1.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .view-btn {
        font-size: 1em;
        font-weight: bold;
        color: var(--color3);
        margin-top: .75em;
        display: inline-block;
    }

    .actions {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: row;
        // margin-bottom: 3vh;
        gap: 1rem;

        .actions-option {
            cursor: pointer;
            width: 2.5rem;
            height: 2.5rem;
            background: inherit;
            // color: var(--primary-color);
            color: var(--color2);
            // box-shadow: 0px 0px 0.4rem var(--color2) inset;
            // box-shadow: 0px 0px 3px var(--color2) inset;
            background-color: var(--color6);
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .actions-option.disable {
            cursor: not-allowed;
            background: var(--background-light);
        }

        .actions-option svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .recipes-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .recipes-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .recipes-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .recipes-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .recipes-container {
        grid-template-columns: repeat(5, 1fr);
    }
}