.section.quote{
    display: block;
    .qoute-text{
        font-size: 1.2em;
        line-height: 1.3;
        color: var(--text-color);
        svg{
            color: var(--primary-color);
            font-size: 1.5em;
            margin-right: .5em;
        }
    }
    .qoute-auther{
        color: var(--text-light);
        text-align: right;
        margin-top: 1em;
    }
}
