/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #8f54a0 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  // width: 15px;
  width: 0px;
}

*::-webkit-scrollbar-track {
  /* background: #ffffff; */
  background: transparent;
  // background: #ffffff; 

}

*::-webkit-scrollbar-thumb {
  background-color: var(--background-color);
  border-radius: 10px;
  border: 3px none #ffffff;
}

.mydialog::-webkit-scrollbar-thumb {
  background-color: var(--background-light);
  border-radius: 10px;
  border: 3px none #ffffff;
}